// Features tiles (core/sections/_features-tiles.scss) ---------
$features-tiles--padding-t__mobile: null; // features tiles, top padding (mobile)
$features-tiles--padding-b__mobile: null; // features tiles, bottom padding (mobile)
$features-tiles--padding-t__desktop: null; // features tiles, top padding (desktop)
$features-tiles--padding-b__desktop: null; // features tiles, bottom padding (desktop)
$features-tiles-header--padding__mobile: null; // features tiles header, bottom padding (mobile)
$features-tiles-header--padding__desktop: null; // features tiles header, bottom padding (desktop)
$features-tiles-items--padding__mobile: 32px; // padding between features tiles (mobile)
$features-tiles-items--padding__desktop: 64px; // padding between features tiles (desktop)
$features-tiles-item--width: 220px; // features tiles item, width
$features-tiles-item--inner-padding-v: 0; // features tiles item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$features-tiles-item--inner-padding-h: 0; // features tiles item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)